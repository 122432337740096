import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, MaterialIcons as Icons } from '@iclinic/design-system';

import { getFeature, isUserAdmin } from '../../state/common/selectors';
import * as S from './ActiveMessagingNotification.styles';
import {
  fetchVisitedMessagingFeature,
  submitVisitedMessagingFeature,
} from 'features/messaging/state/common';

const ActiveMessagingNotification = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(isUserAdmin);
  const feature = useSelector(getFeature);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(fetchVisitedMessagingFeature());
    }
  }, [dispatch, isAdmin]);

  const onCloseNotificationClickHandler = () => {
    dispatch(submitVisitedMessagingFeature());
  };

  if (isAdmin || !feature || feature.state) return null;

  return (
    <S.NotficationWrapper>
      <Alert
        severity="success"
        icon={<Icons.CheckCircleOutline />}
        title="Seu plano de mensageria está ativo!"
        message={
          <>
            <S.Link href="/comunicacao">Personalize</S.Link> as mensagens para
            uma melhor experiência dos seus pacientes
          </>
        }
        action={
          <Button
            color="transparent"
            size="large"
            onClick={onCloseNotificationClickHandler}
          >
            Fechar
          </Button>
        }
      />
    </S.NotficationWrapper>
  );
};

export { ActiveMessagingNotification };
