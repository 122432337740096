import { MaterialIcons as Icons } from '@iclinic/design-system';

import { MenuSlug } from '../state/types';

export const menuOptions = [
  {
    id: 'message',
    text: 'Mensagens',
    icon: Icons.Message,
    permission: MenuSlug.MESSAGES,
    submenu: [
      {
        text: 'Solicitação de confirmação',
        path: '/comunicacao/confirmacao-de-agenda',
        permission: MenuSlug.CONFIRMATION,
        nextJsRoute: false,
      },
      {
        text: 'Lembrete de agendamento',
        path: '/comunicacao/lembrete-de-agenda',
        permission: MenuSlug.REMINDER,
        nextJsRoute: false,
      },
      {
        text: 'Retorno e Fidelização',
        path: `${
          process.env.ICLINIC_APP_URL ?? ''
        }/comunicacao/retorno-e-fidelizacao`,
        permission: MenuSlug.LOYALTY,
        nextJsRoute: true,
      },
    ],
  },
  {
    text: 'Pacote de mensagem',
    icon: Icons.DateUsage,
    path: '/comunicacao/pacote-de-mensagem/detalhes',
    permission: MenuSlug.PACKAGE,
  },
];

export const packageUrl = '/comunicacao/pacote-de-mensagem';
