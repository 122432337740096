import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Body, Box, Heading, Button } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { ErrorAlert } from 'shared/components';
import { trackSignupIClinicPayButtons } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { fetchSSOToken } from 'features/onlinePayment/state/iclinic-pay';
import {
  getSellerProfileSelector,
  getSubscriberUser,
  getUserInfo,
} from 'features/onlinePayment/state/config/register/selectors';
import { ModalPfPj } from 'features/onlinePayment/components/config';
import LpIclinicPayLoader from './LpIclinicPayLoader';
import FormsReceiptsModal from './FormsReceiptsModal';
import CardFlags from './CardFlags';
import FeeTable from './FeeTable';
import VideoModal from './VideoModal';
import { PLANS } from './constants';
import * as S from '../../components/lp-iclinic-pay/styles';

export default function LpIclinicPay(): JSX.Element {
  const { fetching, sellerFetchError } = useSelector(getSellerProfileSelector);
  const isSubscriberUser = useSelector(getSubscriberUser);
  const userData = useSelector(getUserInfo);
  const [modalReceiptsOpen, setModalReceiptsOpen] = useState(false);
  const [modalVideoOpen, setModalVideoOpen] = useState(false);
  const [plan, setPlan] = useState(PLANS.Fast);
  const [modalSellerRegisterType, setModalSellerRegisterType] = useState(false);
  const [showFabButton, setShowFabButton] = useState(false);
  const [positionRegisterBtn, setPositionRegisterBtn] = useState(0);
  const dispatch = useDispatch();

  const goToSupportCenterUrl = () => {
    window.open('https://suporte.iclinic.com.br/pt-br/iclinic-pay', '_blank');
    trackSignupIClinicPayButtons({
      userData,
      buttonName: 'pay_support_center',
      buttonLocation: 'create_payment',
      additionalProperties: 'LP',
    });
  };

  const registerButtonRef = useRef<HTMLButtonElement>(null);
  const OFFSET = 50;

  const toggleButtonVisible = () => {
    const scrolledPage = document.documentElement.scrollTop;
    if (scrolledPage > positionRegisterBtn + OFFSET) {
      setShowFabButton(true);
    } else if (scrolledPage <= positionRegisterBtn + OFFSET) {
      setShowFabButton(false);
    }
  };

  useEffect(() => {
    dispatch(fetchSSOToken());
    if (registerButtonRef.current) {
      setPositionRegisterBtn(registerButtonRef.current.offsetTop);
    }
  }, [dispatch, registerButtonRef]);

  window.addEventListener('scroll', toggleButtonVisible);

  const handleSellerRegisterModal = (buttonProps: string) => {
    setModalSellerRegisterType(true);
    trackSignupIClinicPayButtons({
      userData,
      buttonName: 'pay_first_register',
      buttonLocation: 'onboarding_create_payment',
      additionalProperties: buttonProps,
    });
  };

  if (fetching) {
    return (
      <Container maxWidth="md">
        <LpIclinicPayLoader />
      </Container>
    );
  }

  if (sellerFetchError) {
    return (
      <Container maxWidth="md">
        <ErrorAlert
          message={
            <span dangerouslySetInnerHTML={{ __html: sellerFetchError }} />
          }
        />
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="md">
        <S.Grid container justify="space-between" alignItems="center">
          <S.Grid item xs={12} md={6}>
            <Box mt={3} mb={1} textAlign={{ xs: 'center', md: 'left' }}>
              <S.MainTitle variant="xxl">
                Uma forma responsável de{' '}
                <strong>cuidar dos seus pagamentos</strong>
              </S.MainTitle>
            </Box>
            <Box mb={3} textAlign={{ xs: 'center', md: 'left' }}>
              <Heading variant="md">
                Produto de pagamento para fazer o seu negócio crescer.
              </Heading>
            </Box>
            {isSubscriberUser && (
              <Box
                mb={{ xs: 6, md: 0 }}
                textAlign={{ xs: 'center', md: 'left' }}
                width={{ xs: 'inherit', md: '200px' }}
              >
                <Button
                  buttonRef={registerButtonRef}
                  size="large"
                  fullWidth
                  onClick={() => handleSellerRegisterModal('LP')}
                >
                  Cadastre-se
                </Button>
              </Box>
            )}
            <S.Arrow1 />
          </S.Grid>
          <S.Grid item xs={12} md={6}>
            <Box mb={{ xs: 5, md: 0 }} textAlign="center">
              <S.SectionTitle variant="lg">100 % seguro</S.SectionTitle>
              <Body variant="xs">Seu dinheiro está seguro com a gente</Body>
            </Box>
          </S.Grid>
        </S.Grid>
        <Box mb={{ xs: 4, md: '96px' }}>
          <S.Grid container justify="space-between" alignItems="center">
            <S.Grid item xs={12} md={6} order={2}>
              <Box position="relative">
                <S.CarouselWrapper>
                  <S.Rect1 />
                  <S.Bol1 />
                  <S.ImgSlide>
                    <img
                      alt="Link de pagamento"
                      src={image('onlinePayment.paymentLink')}
                    />
                  </S.ImgSlide>
                </S.CarouselWrapper>
                <S.Arrow2 />
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={5} order={1} alignself="flex-end">
              <Box textAlign="left" mb={{ xs: 4, md: 0 }}>
                <S.SectionTitle variant="lg">
                  Atraia clientes com o meio de pagamentos mais completo
                </S.SectionTitle>
                <S.TestimonialText>
                  <i />
                  <Body variant="xs">
                    &quot;Nesta semana recebi a máquina de cartão do iClinic,
                    vocês sempre trazem inovações como marketing, palestras e
                    cursos, esse movimento de estar sempre à frente é
                    fundamental para mim.&quot;
                  </Body>
                  <S.TestimonialAuthor>
                    <Body variant="xs" bold>
                      Dr. Filipe Damasceno
                    </Body>
                    <Body variant="xs">Cirurgião vascular</Body>
                  </S.TestimonialAuthor>
                </S.TestimonialText>
              </Box>
            </S.Grid>
          </S.Grid>
        </Box>
        <Box mb={{ xs: 6, md: '96px' }} mt={{ xs: 0, md: 3 }}>
          <S.Grid container justify="space-between" alignItems="flex-start">
            <S.Grid item xs={12} md={5}>
              <Box textAlign="left" mb={{ xs: 4, md: 0 }}>
                <S.SectionTitle variant="lg">
                  Controle que torna os seus ganhos aparentes
                </S.SectionTitle>
                <S.TestimonialText>
                  <i />
                  <Body variant="xs">
                    &quot;A antecipação de faturas ajudou muito na gestão de
                    caixa do nosso consultório. Além disso, a plataforma do
                    iClinic Pay permitiu analisarmos os resultados como nunca
                    havíamos feito antes.&quot;
                  </Body>
                  <S.TestimonialAuthor>
                    <Body variant="xs" bold>
                      Dra. Maria Augusta Ornellas
                    </Body>
                    <Body variant="xs">Oftalmologista</Body>
                  </S.TestimonialAuthor>
                </S.TestimonialText>
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={6}>
              <Box position="relative" textAlign="left" mt={{ xs: 1, md: 3 }}>
                <S.CarouselWrapper>
                  <S.Rect2 />
                  <S.Bol2 />
                  <S.ImgSlide>
                    <img
                      alt="Histórico de transação"
                      src={image('onlinePayment.transactionHistory')}
                    />
                  </S.ImgSlide>
                </S.CarouselWrapper>
                <S.Arrow3 />
              </Box>
            </S.Grid>
          </S.Grid>
        </Box>
        <Box mb={{ xs: 1, md: 6 }}>
          <S.Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
          >
            <Box mb={{ xs: 3, md: 6 }} textAlign="center">
              <S.MainTitle variant="xl">
                Recupere o controle dos seus pagamentos <br />
                <strong>com apenas um produto</strong>
              </S.MainTitle>
            </Box>
            <S.Grid item xs={12} md={4}>
              <Box textAlign="center">
                <S.ImgWrapper>
                  <img
                    alt="Imagem de um relógio"
                    src={image('onlinePayment.clock')}
                  />
                </S.ImgWrapper>
                <Box
                  my={{ xs: 3, md: 3 }}
                  mx="auto"
                  width={{ xs: 'inherit', md: '250px' }}
                >
                  <Body variant="xs">
                    Não perca tempo conferindo o pagamento das consultas
                    realizadas na sua clínica.
                  </Body>
                </Box>
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={4}>
              <Box textAlign="center">
                <S.ImgWrapper>
                  <img
                    alt="Imagem de um fluxograma"
                    src={image('onlinePayment.flowchart')}
                  />
                </S.ImgWrapper>
                <Box
                  my={{ xs: 3, md: 3 }}
                  mx="auto"
                  width={{ xs: 'inherit', md: '250px' }}
                >
                  <Body variant="xs">
                    Saiba em um só lugar quanto falta para você receber.
                    Antecipe seus recebíveis para aproveitar as melhores
                    negociações.
                  </Body>
                </Box>
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={4}>
              <Box textAlign="center">
                <S.ImgWrapper>
                  <img
                    alt="Imagem de um celular"
                    src={image('onlinePayment.mobile')}
                  />
                </S.ImgWrapper>
                <Box
                  my={{ xs: 3, md: 3 }}
                  mx="auto"
                  width={{ xs: 'inherit', md: '250px' }}
                >
                  <Body variant="xs">
                    Seja pelo seu computador ou celular, queremos que você tenha
                    controle em tempo real.
                  </Body>
                </Box>
              </Box>
            </S.Grid>
          </S.Grid>
        </Box>
        <Box mb={{ xs: 4, md: '75px' }}>
          <Box mb={{ xs: 3, md: 6 }} textAlign="center">
            <S.MainTitle variant="xl">
              Alguns dos nossos <strong>produtos</strong>
            </S.MainTitle>
          </Box>
          <S.Grid container justify="center">
            <S.Grid item xs={12} md={5} order={2}>
              <Box textAlign="center">
                <S.ImgWrapper>
                  <img
                    alt="Maquininha iClinicPay"
                    src={image('onlinePayment.paymentMachine')}
                  />
                </S.ImgWrapper>
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={5} order={1} alignself="flex-center">
              <Box textAlign="left" mb={{ xs: 1, md: 0 }} mt={{ xs: 0, md: 5 }}>
                <S.SectionTitle variant="lg">
                  Maquininha iClinic Pay
                </S.SectionTitle>
                <S.TestimonialText>
                  <i />
                  <Body variant="xs">
                    Feita para médicos, suporte exclusivo e eficiente, taxas
                    competitivas. Maquininha com bobina, pagamento por
                    aproximação e conexão Wi-fi.
                  </Body>
                </S.TestimonialText>
              </Box>
            </S.Grid>
          </S.Grid>

          <S.Grid container justify="center" alignItems="center">
            <S.Grid item xs={12} md={5}>
              <Box textAlign="left" mb={{ xs: 1, md: 0 }} mt={{ xs: 0, md: 2 }}>
                <S.SectionTitle variant="lg">Link de pagamento</S.SectionTitle>
                <S.TestimonialText>
                  <i />
                  <Body variant="xs">
                    Aumente sua lucratividade compartilhando links de pagamento
                    com seus pacientes! Essa ferramenta é exclusiva para
                    clientes iClinic. Para utilizar, basta criar um cadastro no
                    menu de Pagamento Online!
                  </Body>
                </S.TestimonialText>
              </Box>
            </S.Grid>
            <S.Grid item xs={12} md={5}>
              <Box textAlign="center" mt={{ xs: 1, md: 3 }}>
                <S.ImgWrapper>
                  <img
                    alt="Link de pagamento"
                    src={image('onlinePayment.desktopPaymentLink')}
                  />
                </S.ImgWrapper>
              </Box>
            </S.Grid>
          </S.Grid>
        </Box>
        <Box mb={{ xs: 4, md: '80px' }} textAlign="center">
          <S.MainTitle variant="xl">
            Formas de recebimento <br />{' '}
            <strong>simples e transparentes</strong>
          </S.MainTitle>
          <Box my={1}>
            <Body variant="sm" bold>
              Escolha a modalidade que se adequa a suas necessidades.
            </Body>
          </Box>
          <Button
            color="transparent"
            onClick={() => setModalReceiptsOpen(true)}
            size="small"
          >
            Saiba mais
          </Button>
          <Box mt={{ xs: 3, md: 6 }} mb={4}>
            <CardFlags />
          </Box>

          <Box mb={6}>
            <FeeTable userData={userData} plan={plan} setPlan={setPlan} />
          </Box>
        </Box>
        <Box textAlign={{ xs: 'center', md: 'left' }} mb={4}>
          <Box mb={3}>
            <S.MainTitle variant="xl">
              Ficou com <strong>alguma dúvida?</strong>
            </S.MainTitle>
          </Box>
          <Button size="large" color="secondary" onClick={goToSupportCenterUrl}>
            Central de Suporte
          </Button>
        </Box>
        {isSubscriberUser && (
          <S.FloatingWrapper>
            <S.Fab
              visible={showFabButton}
              onClick={() => handleSellerRegisterModal('botão flutuante')}
            >
              <span>Cadastre-se</span>
            </S.Fab>
          </S.FloatingWrapper>
        )}
      </Container>

      <FormsReceiptsModal
        open={modalReceiptsOpen}
        onClose={setModalReceiptsOpen}
      />

      <VideoModal open={modalVideoOpen} onClose={setModalVideoOpen} />

      <ModalPfPj
        userData={userData}
        open={modalSellerRegisterType}
        onClose={() => setModalSellerRegisterType(false)}
      />
    </>
  );
}
