import { call, put, all, takeLatest, select, delay } from 'redux-saga/effects';
import { Response } from '@types';

import history from 'routes/history';
import { fetchInfosZipCode } from 'services/iclinic/easyZipCode';
import { onlyNumbers } from 'shared/utils/formatters';
import { trimObject } from 'shared/utils/object';
import { captureException } from 'shared/utils/handlerErrors';
import {
  createSellerRequest,
  getUploadURL,
  uploadDocument,
  updatePhysicianRequest,
  fetchPhysician,
  fetchSellerAccountsByPhysicianId,
  fetchSellerDocumentProof,
  updateDefaultSellerRequest,
  sendSellerHubspot,
} from 'features/onlinePayment/services/config';
import {
  SellerRawData,
  UploadDocumentRawData,
  FetchSellerRawData,
  PhysicianRawData,
  DocumentProofRawData,
  UpdateDefaultSellerData,
} from 'features/onlinePayment/services/config/types';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';
import { listCBOState } from 'features/tuss/cbo/state/selectors';
import {
  trackSignupIClinicPayForm,
  UserData,
} from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import {
  createSellerSuccess,
  init,
  save,
  createSeller,
  updatePhysician,
  fetchPhysicianSuccess,
  fetchPhysicianFailure,
  fetchSellerSuccess,
  fetchSellerFailure,
  updatePhysicianSuccess,
  updatePhysicianFailure,
  createSellerFailure,
  sendDocumentsSuccess,
  submittedSuccess,
  fetchZipCode,
  fetchZipCodeSuccess,
  fetchZipCodeFailure,
  chooseSeller,
  fetchSellerDocumentProofSuccess,
  fetchSeller,
  updateDefaultSeller,
  updateDefaultSellerFailure,
  updateDefaultSellerSuccess,
  sendInteractionToHubspot,
} from '.';
import { SellerStatus, SellerType } from '../../constants';
import {
  normalizeFetchSellersByPhysicianId,
  normalizePhysician,
  normalizeSeller,
} from './normalizers';
import {
  getUserInfo,
  getPhysicianId,
  getSellerProfiles,
  isSellerAlreadyCreated,
} from './selectors';
import { SellerProfiles } from '../types';
import { configurationRoutes } from '../constants';
import { handleCreateSellerErrors } from './handleErrors';

type SaveAction = ReturnType<typeof save>;
type CreateSellerAction = ReturnType<typeof createSeller>;
type FetchZipCodeAction = ReturnType<typeof fetchZipCode>;
type ChooseSellerAction = ReturnType<typeof chooseSeller>;
type UpdateDefaultSellerAction = ReturnType<typeof updateDefaultSeller>;

export function isSellerFullyCreated(seller?: SellerRawData | null) {
  return (
    (seller?.status &&
      seller.status !== SellerStatus.Pending &&
      seller.min_installment_value != null &&
      seller.bank_account != null) ||
    false
  );
}

export function* redirectTo(pathname: string) {
  yield call([history, history.push], {
    pathname,
  });
}

export function* fetchSellerDocumentProofWorker(seller?: SellerRawData | null) {
  try {
    if (seller?.status !== SellerStatus.Refused) {
      return null;
    }
    const { data }: Response<DocumentProofRawData[]> = yield call(
      fetchSellerDocumentProof,
      seller?.id,
    );
    return data;
  } catch (error) {
    return null;
  }
}

export function* loadSellersDocumentProofWorker(
  sellers: SellerProfiles | null,
) {
  const [individual, business]: (null | DocumentProofRawData[])[] = yield all([
    call(fetchSellerDocumentProofWorker, sellers?.individual),
    call(fetchSellerDocumentProofWorker, sellers?.business),
  ]);
  yield put(fetchSellerDocumentProofSuccess({ individual, business }));
}

export function* fetchSellerWorker() {
  try {
    const physicianId: number = yield select(getPhysicianId);
    const { data }: Response<FetchSellerRawData> = yield call(
      fetchSellerAccountsByPhysicianId,
      physicianId,
    );
    const sellerProfiles = normalizeFetchSellersByPhysicianId(data);
    yield put(fetchSellerSuccess(sellerProfiles));

    /** TODO
     * the API is not ready yet!
     * if (sellerProfiles != null) {
     * yield call(loadSellersDocumentProofWorker, sellerProfiles);
     * }
     */
  } catch (error) {
    captureException(error);
    yield put(fetchSellerFailure());
  }
}

export function* workerTrackerSellerId(sellerType: string) {
  yield delay(5000);
  yield call(fetchSellerWorker);
  const sellerProfiles: SellerProfiles = yield select(getSellerProfiles);
  const userData: UserData = yield select(getUserInfo);
  const sellerIdByFinancialServicesPF =
    sellerProfiles.individual?.financial_services_id;
  const sellerIdByFinancialServicesPJ =
    sellerProfiles.business?.financial_services_id;

  const flowType =
    sellerType === SellerType.PF ? 'physical_person' : 'legal_person';
  const stepValue =
    sellerType === SellerType.PF
      ? sellerIdByFinancialServicesPF
      : sellerIdByFinancialServicesPJ;
  const stepNum = sellerType === SellerType.PF ? 25 : 29;

  if (sellerProfiles !== null) {
    trackSignupIClinicPayForm({
      userData,
      flowType,
      stepName: 'success',
      stepNum,
      stepValue,
    });
  }
}

export function* sellerRedirect() {
  try {
    const sellerProfiles: SellerProfiles | null | undefined = yield select(
      getSellerProfiles,
    );
    if (sellerProfiles != null) {
      yield call(redirectTo, configurationRoutes.profile);
    } else {
      yield call(redirectTo, configurationRoutes.default);
    }
  } catch (error) {
    captureException(error);
  }
}

export function* chooseSellerWorker({ payload }: ChooseSellerAction) {
  if (isSellerFullyCreated(payload?.seller)) {
    yield call(redirectTo, configurationRoutes.edit);
  } else {
    yield call(redirectTo, configurationRoutes.register);
  }
}

export function* fetchPhysicianWorker() {
  try {
    const physicianId: number = yield select(getPhysicianId);
    const { data: physician }: Response<PhysicianRawData> = yield call(
      fetchPhysician,
      physicianId,
    );

    yield put(fetchPhysicianSuccess(physician));
  } catch (error) {
    captureException(error);
    yield put(fetchPhysicianFailure());
  }
}

export function* initWorker() {
  yield all([call(fetchSellerWorker), call(fetchPhysicianWorker)]);
  yield call(sellerRedirect);
}

export function* uploadDocumentWorker(document: File) {
  const { data }: Response<UploadDocumentRawData> = yield call(
    getUploadURL,
    document,
  );
  const { key, url } = data;
  yield call(uploadDocument, url, document);

  return key;
}

export function* createSellerWorker({ payload: seller }: CreateSellerAction) {
  try {
    const { objects: cboList } = yield select(listCBOState);
    const normalizedSeller = normalizeSeller(seller, cboList);
    const { data }: Response<SellerRawData> = yield call(
      createSellerRequest,
      normalizedSeller,
    );

    yield put(createSellerSuccess(data));
    yield put(submittedSuccess());
    yield put(sendDocumentsSuccess());
    yield call(workerTrackerSellerId, normalizedSeller.type);
  } catch (error) {
    captureException(error);
    const errorMessage = handleCreateSellerErrors(error);
    yield put(createSellerFailure(errorMessage));
  }
}

export function* updatePhysicianWorker({
  payload: seller,
}: CreateSellerAction) {
  try {
    const physicianId: number = yield select(getPhysicianId);
    const { objects: cboList } = yield select(listCBOState);
    const physician = normalizePhysician(seller, cboList);
    yield call(updatePhysicianRequest, physicianId, physician);
    yield put(updatePhysicianSuccess());
  } catch (error) {
    captureException(error);
    yield put(updatePhysicianFailure());
  }
}

export function* saveWorker({ payload }: SaveAction) {
  const sellerForm = trimObject(payload);
  const isSellerCreated: boolean = yield select(isSellerAlreadyCreated);
  if (!isSellerCreated) {
    yield put(updatePhysician(sellerForm));
    yield put(createSeller(sellerForm));
  }

  yield put(submittedSuccess());
  yield put(sendDocumentsSuccess());
}

export function* fetchZipCodeWorker({ payload: zipCode }: FetchZipCodeAction) {
  try {
    const normalizeCep = onlyNumbers(zipCode);
    const { data }: Response<ZipCodeRawData> = yield call(
      fetchInfosZipCode,
      normalizeCep,
    );
    yield put(fetchZipCodeSuccess(data));
  } catch (error) {
    yield put(fetchZipCodeFailure());
    captureException(error);
  }
}

export function* updateDefaultSellerWorker({
  payload: sellerId,
}: UpdateDefaultSellerAction) {
  try {
    const {
      data: { is_default, type },
    }: Response<UpdateDefaultSellerData> = yield call(
      updateDefaultSellerRequest,
      sellerId,
    );
    const sellerProfiles: SellerProfiles = yield select(getSellerProfiles);

    const resetIsDefaultProfiles = {
      [SellerType.PF]: {
        ...sellerProfiles[SellerType.PF],
        is_default: false,
      },
      [SellerType.PJ]: {
        ...sellerProfiles[SellerType.PJ],
        is_default: false,
      },
    };

    const defaultProfile = Object.assign(resetIsDefaultProfiles[type], {
      is_default,
    });

    const updatedSellerProfiles = {
      ...resetIsDefaultProfiles,
      [type]: defaultProfile,
    } as SellerProfiles;

    yield put(updateDefaultSellerSuccess(updatedSellerProfiles));
  } catch (error) {
    yield put(updateDefaultSellerFailure());
    captureException(error);
  }
}

export function* sendInteractionHubspotWorker() {
  try {
    yield call(sendSellerHubspot);
  } catch (error) {
    captureException(error);
  }
}

export default function* watchSaveWorker() {
  yield takeLatest(init, initWorker);
  yield takeLatest(save, saveWorker);
  yield takeLatest(createSeller, createSellerWorker);
  yield takeLatest(updatePhysician, updatePhysicianWorker);
  yield takeLatest(fetchZipCode, fetchZipCodeWorker);
  yield takeLatest(chooseSeller, chooseSellerWorker);
  yield takeLatest(fetchSeller, fetchSellerWorker);
  yield takeLatest(updateDefaultSeller, updateDefaultSellerWorker);
  yield takeLatest(sendInteractionToHubspot, sendInteractionHubspotWorker);
}
