import { PackagesSlug, TemplateKind } from '../services/types';

type PackageItem = {
  price: string;
  name: string;
  sendQuantity: number;
  slug: PackagesSlug;
};

export const packages: PackageItem[] = [
  {
    price: 'R$ 29,90',
    name: 'Gestão básica',
    sendQuantity: 85,
    slug: PackagesSlug.BASIC,
  },
  {
    price: 'R$ 56,90',
    name: 'Gestão iniciante',
    sendQuantity: 200,
    slug: PackagesSlug.INITIAL,
  },
  {
    price: 'R$ 149,90',
    name: 'Gestão avançada',
    sendQuantity: 600,
    slug: PackagesSlug.ADVANCED,
  },

];

export const kindLabelMap = {
  [TemplateKind.CONFIRMATION]: 'Confirmação de agenda',
  [TemplateKind.REMINDER]: 'Lembrete de agenda',
};

export const contractorUpdateMessages = {
  success: 'Nova configuração salva com sucesso!',
  failed: 'Algum erro ocorreu, por favor tente novamente mais tarde!',
};
