import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouterProps,
  Redirect,
} from 'react-router-dom';

import { getAvailableMenus } from '../state/common/selectors';
import { MenuSlug } from '../state/types';
import { fetchMenuPermissions } from '../state/common';
import { StoreState } from 'state/rootReducer';
import { RequestStatus } from 'shared/constants/State';

interface PermissedRouteProps extends ReactRouterProps {
  permission: MenuSlug;
  component: React.ComponentType;
}

const PermissedRoute = ({
  permission,
  component: Component,
  ...rest
}: PermissedRouteProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMenuPermissions(true));
  }, [dispatch]);

  const fetcherMenuPermissions = useSelector(
    (state: StoreState) => state.messaging.common.fetcherMenuPermissions,
  );

  const availableMenus = useSelector(getAvailableMenus);
  const hasPermission = availableMenus?.includes(permission);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (fetcherMenuPermissions === RequestStatus.Pending) {
          return null;
        }
        if (fetcherMenuPermissions === RequestStatus.Error) {
          return <Redirect to="/comunicacao" />;
        }

        if (hasPermission) {
          return <Component />;
        }
        if (!hasPermission && availableMenus?.length === 0) {
          return <Redirect to="/404" />;
        }

        return null;
      }}
    />
  );
};

export default PermissedRoute;
