import React, { useState } from 'react';
import { Body, Button } from '@iclinic/design-system';
import { useDispatch } from 'react-redux';
import Icon from '@material/react-material-icon';

import { Tooltip } from 'ui/overlay';
import history from 'routes/history';
import {
  CardWrapper,
  PageHeader,
  PageWrapper,
  SectionHeader,
} from '../../../components';
import { packages } from '../../../constants';
import { ModalSubscriptionSummary } from './ModalSubscriptionSummary';
import {
  fetchSubscriptionSummary,
  setSelectedPackage,
  toggleOpenSummary,
} from '../../../state/messagePackage/checkout';
import { PackagesSlug } from '../../../services/types';
import { ModalSuccessSubscription } from './ModalSuccessSubscription';
import { PackageCard } from './PackageCard';
import * as S from './PackageSelector.styles';

const PackageSelector = () => {
  const dispatch = useDispatch();
  const [checkedPackage, setCheckedPackage] = useState<PackagesSlug>(
    PackagesSlug.BASIC,
  );

  const onClickPackageHandler = (slug: PackagesSlug) => {
    setCheckedPackage(slug);
  };

  const onSubmit = () => {
    if (!checkedPackage) return;

    dispatch(setSelectedPackage(checkedPackage));
    dispatch(toggleOpenSummary());
    dispatch(fetchSubscriptionSummary());
  };

  const onBackClickHandler = () => {
    history.goBack();
  };

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Pacote de mensagem"
          description="Encontre o plano de mensageria perfeito para você, comparando opções que se adequem às suas necessidades e orçamento."
        />

        <CardWrapper>
          <S.SendWrapper>
            <SectionHeader
              title="Envio de comunicações"
              description="Essas são as comunicações que serão enviadas de forma automática
              para seus pacientes:"
            />

            <S.HorizontalWrapper>
              <S.CheckIcon fontSize="small" />

              <S.BodySmooth variant="xxs">Confirmação de agenda</S.BodySmooth>
            </S.HorizontalWrapper>

            <S.HorizontalWrapper>
              <S.CheckIcon fontSize="small" />

              <S.BodySmooth variant="xxs">Lembrete de agenda</S.BodySmooth>
            </S.HorizontalWrapper>

            <S.HorizontalWrapper>
              <S.CheckIcon fontSize="small" />

              <S.BodySmooth variant="xxs">Pesquisa de satisfação</S.BodySmooth>
              <Tooltip content="Disponível a partir do plano plus" align="top">
                <Icon icon="info" />
              </Tooltip>
            </S.HorizontalWrapper>
            <S.HorizontalWrapper>
              <S.CheckIcon fontSize="small" />

              <S.BodySmooth variant="xxs">Retorno e Fidelização (Beta)</S.BodySmooth>
            </S.HorizontalWrapper>

          </S.SendWrapper>

          <S.PackagesSectionWrapper>
            <Body variant="sm" bold>
              Pacotes disponíveis
            </Body>

            <S.PackageCardsWrapper>
              {packages.map(({ name, price, sendQuantity, slug }) => (
                <PackageCard
                  key={slug}
                  packageName={name}
                  sendQuantity={sendQuantity}
                  price={price}
                  slug={slug}
                  selected={checkedPackage === slug}
                  onClick={onClickPackageHandler}
                />
              ))}
            </S.PackageCardsWrapper>
          </S.PackagesSectionWrapper>

          <S.FooterSectionWrapper>
            <Body variant="xxs">
              <em>
                *Ao fim do pacote contratado, será cobrado o envio excedente
                unitário por <strong>R$0,31</strong>
              </em>
            </Body>

            <S.ActionsWrapper>
              <Button color="transparent" onClick={onBackClickHandler}>
                Voltar
              </Button>
              <Button
                onClick={onSubmit}
                data-ga="relationship_packet-selection-page_buy"
                data-sp="relationship_packet-selection-page_buy"
              >
                Contratar pacote
              </Button>
            </S.ActionsWrapper>
          </S.FooterSectionWrapper>
        </CardWrapper>
      </PageWrapper>

      <ModalSubscriptionSummary />
      <ModalSuccessSubscription />
    </>
  );
};

export { PackageSelector };
