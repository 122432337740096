import React, { useCallback, useState } from 'react';
import {
  Body,
  CircularProgress,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { menuOptions } from '../../constants/menuData';
import * as S from './SideBar.styles';
import {
  getAvailableMenus,
  isFetchingPermissionsMenu,
} from 'features/messaging/state/common/selectors';

const { KeyboardArrowUpIcon, DateUsage } = MaterialIcons;
const { Collapse } = MaterialUICore;

export default function SideBar() {
  const isFetchingPermissions = useSelector(isFetchingPermissionsMenu);
  const availableMenus = useSelector(getAvailableMenus);
  const [selectedMenu, setSelectedMenu] = useState('');
  const pathName = window.location.pathname;

  const handleMenuWithSubmenuClick = useCallback((menuId: string) => {
    setSelectedMenu((prev) => (menuId === prev ? '' : menuId));
  }, []);

  const getItemStyle = useCallback(
    (path: string) => (path === pathName ? 'selected' : ''),
    [pathName],
  );

  if (isFetchingPermissions) {
    return (
      <S.LoaderWrapper>
        <CircularProgress data-testid="sidebar-loader" />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.SideBar>
      {menuOptions
        .filter((option) => availableMenus?.includes(option.permission))
        .map(({ id, text, icon: Icon, path, submenu }) =>
          submenu?.length ? (
            <React.Fragment key={id}>
              <S.MenuItem
                className={selectedMenu === id ? '' : 'rotate'}
                onClick={() => handleMenuWithSubmenuClick(id)}
              >
                <Icon />
                <Body variant="xs">{text}</Body>
                <KeyboardArrowUpIcon />
              </S.MenuItem>

              <Collapse in={selectedMenu === id}>
                <div>
                  {submenu
                    .filter((option) =>
                      availableMenus?.includes(option.permission),
                    )
                    .map((submenuOption) =>
                      submenuOption.nextJsRoute ? (
                        <S.ExternalMenuLink
                          href={submenuOption.path}
                          key={submenuOption.path}
                        >
                          <S.SubmenuItem
                            className={getItemStyle(submenuOption.path)}
                          >
                            <Body
                              variant="xs"
                              className={getItemStyle(submenuOption.path)}
                            >
                              {submenuOption.text}
                            </Body>
                          </S.SubmenuItem>
                        </S.ExternalMenuLink>
                      ) : (
                        <S.MenuLink
                          to={submenuOption.path}
                          key={submenuOption.path}
                        >
                          <S.SubmenuItem
                            className={getItemStyle(submenuOption.path)}
                          >
                            <Body
                              variant="xs"
                              className={getItemStyle(submenuOption.path)}
                            >
                              {submenuOption.text}
                            </Body>
                          </S.SubmenuItem>
                        </S.MenuLink>
                      ),
                    )}
                </div>
              </Collapse>
            </React.Fragment>
          ) : (
            <S.MenuLink key={text} to={path || ''}>
              <S.MenuItem className={getItemStyle(path || '')}>
                <DateUsage />
                <Body variant="xs" className={getItemStyle(path || '')}>
                  {text}
                </Body>
              </S.MenuItem>
            </S.MenuLink>
          ),
        )}
    </S.SideBar>
  );
}
