export enum PLANS {
  Regular = 'regular',
  Fast = 'fast',
}

export const REGULAR_PLAN_FEES = [
  '1,60%',
  '2,22%',
  '2,94%',
  '2,94%',
  '2,94%',
  '2,94%',
  '2,94%',
  '3,39%',
  '3,39%',
  '3,39%',
  '3,39%',
  '3,39%',
  '3,39%',
];

export const FAST_PLAN_FEES = [
  '1,60%',
  '4,42%',
  '6,26%',
  '7,39%',
  '8,53%',
  '9,69%',
  '10,85%',
  '12,48%',
  '13,68%',
  '14,88%',
  '16,10%',
  '17,34%',
  '18,58%',
];
