import React, { useEffect, useState } from 'react';
import {
  Body,
  MaterialIcons as Icons,
  MaterialUICore,
} from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import {
  Title,
  StyledDialog,
  StyledDialogHeader,
  StyledDialogContent,
  Text,
  StyledDialogActions,
  DismissButton,
  TryOutButton,
} from './Popover.styles';
import { getPhysicianAndClinicId } from 'features/feedDataLayer/state/selectors';
import { dispatchGaClick, dispatchGaEvent } from 'shared/utils/googleAnalytics';

const { useMediaQuery, useTheme } = MaterialUICore;

export type PopoverProps = {
  onClickTry: () => Promise<void>;
  isLoading: boolean;
};

const Popover = ({ onClickTry, isLoading }: PopoverProps) => {
  const { physicianId } = useSelector(getPhysicianAndClinicId);
  const [alreadyClosed, setAlreadyClosed] = useState(true);
  const theme = useTheme();
  const isLayoutMobile = useMediaQuery(theme.breakpoints.down(390));
  const buttonSize = isLayoutMobile ? 'small' : 'medium';
  const bodySize = isLayoutMobile ? 'sm' : 'xs';

  const handleClose = (isDimiss: boolean) => {
    if (isDimiss) {
      dispatchGaClick({ eventName: 'resumoai_dispensar' });
    }

    localStorage.setItem(
      'popover-ia-physician-id',
      physicianId?.toString() as string,
    );
    setAlreadyClosed(true);
  };

  useEffect(() => {
    const savedPhysicianId = localStorage.getItem('popover-ia-physician-id');
    setAlreadyClosed(physicianId?.toString() === savedPhysicianId);
  }, [physicianId]);

  useEffect(() => {
    if (!alreadyClosed) {
      dispatchGaEvent('impression', {
        event_name: 'resumoai',
        component: 'popup',
      });
    }
  }, [alreadyClosed]);

  return (
    <StyledDialog
      keepMounted={false}
      disableEnforceFocus
      disableAutoFocus
      disablePortal
      hideBackdrop
      disableScrollLock
      open={!alreadyClosed}
    >
      <StyledDialogHeader title="" onClose={() => handleClose(true)} />

      <StyledDialogContent>
        <Body variant={bodySize}>
          <Icons.DiamondIA fontSize="large" />
          <Title>Otimização de prontuário</Title>
          <Text>
            Parabéns!
            <br />
            Você recebeu acesso antecipado ao resumo do histórico clínico do seu
            paciente com IA.
            <br />
          </Text>
          <Text>
            Este é um teste <strong>gratuito</strong> para uma funcionalidade em
            desenvolvimento que resume os últimos 3 prontuários do seu paciente
            para tornar seu atendimento mais rápido e eficiente.
          </Text>
        </Body>
      </StyledDialogContent>

      <StyledDialogActions>
        <DismissButton
          size={buttonSize}
          color="transparent"
          onClick={() => handleClose(true)}
        >
          Dispensar
        </DismissButton>
        <TryOutButton
          variant="contained"
          size={buttonSize}
          endIcon={<Icons.ArrowFowardRounded />}
          onClick={async () => {
            await onClickTry();
            handleClose(false);
          }}
          isLoading={isLoading}
          data-ga="resumoai_experimentar"
        >
          Experimentar
        </TryOutButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default Popover;
