const { ASSETS_PATH } = process.env;

const images = {
  onlinePayment: {
    proofOfAddress: `${
      ASSETS_PATH as string
    }images/online-payment/proof-of-address.svg`,
    cnpj: `${ASSETS_PATH as string}images/online-payment/cnpj.svg`,
    document: `${ASSETS_PATH as string}images/online-payment/document.svg`,
    defaultAvatar: `${
      ASSETS_PATH as string
    }images/online-payment/default-avatar.png`,
    released: `${ASSETS_PATH as string}images/online-payment/released.svg`,
    sendLink: `${ASSETS_PATH as string}images/online-payment/sendLink.svg`,
    whoPaid: `${ASSETS_PATH as string}images/online-payment/whoPaid.svg`,
    dashboardCheck: `${
      ASSETS_PATH as string
    }images/online-payment/dashboard-check.svg`,
    moneySlide: `${ASSETS_PATH as string}images/online-payment/money-slide.svg`,
    walletCheck: `${
      ASSETS_PATH as string
    }images/online-payment/wallet-check.svg`,
    paperAndPencil: `${
      ASSETS_PATH as string
    }images/online-payment/paper-and-pencil.svg`,
    financialDashboard: `${
      ASSETS_PATH as string
    }images/online-payment/financial-dashboard.svg`,
    arrow_1: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/arrow-1.svg`,
    arrow_2: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/arrow-2.svg`,
    arrow_3: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/arrow-3.svg`,
    playVideo: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/play-video.png`,
    bgScreen: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/bg-screen.svg`,
    clock: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/clock.svg`,
    flowchart: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/flowchart.svg`,
    mobile: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/mobile.svg`,
    regularPlan: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/regular-plan.svg`,
    fastPlan: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/fast-plan.svg`,
    mastercard: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/mastercard.svg`,
    visa: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/visa.svg`,
    elo: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/elo.svg`,
    americanExpress: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/american-express.svg`,
    hiper: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/hiper.svg`,
    hipercard: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/hipercard.svg`,
    pix: `${
      ASSETS_PATH as string
    }images/online-payment/credit-card-flags/pix.svg`,
    transactionHistory: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/transaction-history.jpg`,
    paymentLink: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/payment-link.jpg`,
    paymentMachine: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/maquininha-stone.png`,
    desktopPaymentLink: `${
      ASSETS_PATH as string
    }images/online-payment/lp-iclinic-pay/desktop-payment-link.png`,
    logoCheckout: `${
      ASSETS_PATH as string
    }images/online-payment/checkout-images/logo.svg`,
    bgCheckout: `${
      ASSETS_PATH as string
    }images/online-payment/checkout-images/background.svg`,
    pixIcon: `${ASSETS_PATH as string}images/online-payment/pixIcon.svg`,
  },
};

export default images;
