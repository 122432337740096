import {
  MaterialUICore,
  axiomTokens,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogHeader,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  pointerEvents: 'none',

  '& .MuiDialog-container': {
    pointerEvents: 'none',
  },

  '& .MuiDialog-paper': {
    position: 'fixed',
    bottom: '88px',
    right: '92px',
    margin: 0,
    overflow: 'visible',

    [theme.breakpoints.down('md')]: {
      bottom: '153px',
      right: '18px',
      marginLeft: '15px',
    },

    [theme.breakpoints.down(390)]: {
      bottom: '132px',
    },
  },

  '& .MuiDialog-paper::after': {
    content: '""',
    position: 'absolute',
    bottom: '-18px',
    right: '13px',
    width: '0',
    height: '0',
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderTop: `24px solid #1b2d43`,
    zIndex: 1,
  },

  '& .MuiPaper-root': {
    backgroundColor: axiomTokens.color.inverse.background[1],
    maxWidth: '576px',
    pointerEvents: 'all',
  },

  '& .MuiPaper-root::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(180deg, ${axiomTokens.color.inverse.background[1]} 0%, rgba(11, 99, 203, 0.5) 120%)`,
    opacity: '0.5',
    pointerEvents: 'none',
    zIndex: 1,
    borderRadius: axiomTokens.border.radius.sm,
  },
}));

export const StyledDialogHeader = styled(DialogHeader)(() => ({
  padding: `${axiomTokens.spacing[2]} ${axiomTokens.spacing[3]}`,
  zIndex: 2,

  '& .MuiSvgIcon-root': {
    fill: axiomTokens.color.inverse.icon.default,
  },
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
  padding: axiomTokens.spacing[4],
  position: 'relative',
  zIndex: 2,

  '& path': {
    fill: axiomTokens.color.icon.onInteractive.accent,
  },

  '& .MuiSvgIcon-root': {
    '@media (max-height: 719px)': {
      display: 'none',
    },
  },
}));

export const Title = styled('h2')(({ theme }) => ({
  color: axiomTokens.color.inverse.typeface.strong,
  font: axiomTokens.font.family.base,
  fontWeight: axiomTokens.font.weight.bold,
  fontSize: axiomTokens.font.size.md,
  margin: `${axiomTokens.spacing[4]} 0 ${axiomTokens.spacing[2]} 0`,

  [theme.breakpoints.down(390)]: {
    fontSize: axiomTokens.font.size.sm,
    letterSpacing: axiomTokens.font.letterSpacing.sm,
  },
}));

export const Text = styled('p')(({ theme }) => ({
  font: axiomTokens.font.family.base,
  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.xs,
  letterSpacing: axiomTokens.font.letterSpacing.md,
  color: axiomTokens.color.inverse.typeface.strong,
  lineHeight: axiomTokens.spacing[6],

  [theme.breakpoints.down(390)]: {
    fontSize: axiomTokens.font.size.xxs,
    fontWeight: axiomTokens.font.weight.regular,
    lineHeight: '19.88px',
    letterSpacing: axiomTokens.font.letterSpacing.sm,
  },
}));

export const StyledDialogActions = styled(DialogActions)(() => ({
  position: 'relative',
  zIndex: 2,
}));

export const DismissButton = styled(Button)(() => ({
  color: axiomTokens.color.inverse.typeface.strong,

  '&:hover': {
    background: axiomTokens.color.interactive.subtle.hovered,
    color: axiomTokens.color.branding[60],
  },
}));

export const TryOutButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: axiomTokens.color.interactive.accent.hovered,
    color: axiomTokens.color.branding[160],
    '& path': {
      fill: axiomTokens.color.branding[160],
    },
  },
}));
