import { axiomTokens, MaterialUICore } from '@iclinic/design-system';
import { Link } from 'react-router-dom';

const { styled, MenuItem: DSMenuItem } = MaterialUICore;

const { color, border, font, spacing } = axiomTokens;

export const SideBar = styled('div')({
  position: 'absolute',
  width: '100%',
  padding: `${spacing[3]} 0`,
});

export const MenuItem = styled(DSMenuItem)({
  padding: `${spacing[4]} ${spacing[3]}`,
  borderRadius: border.radius.md,
  fontSize: font.size.xs,

  '& .MuiSvgIcon-root': {
    marginRight: spacing[2],
    transition: 'all .1s ease-in-out',
  },

  '& .MuiTypography-root': {
    width: '100%',
  },

  '&.selected': {
    backgroundColor: color.surface.selected.default,

    '& .MuiTypography-root': {
      color: color.interactive.accent.default,
    },

    '& .MuiSvgIcon-root': {
      color: color.icon.activated,
    },
  },

  '&.rotate': {
    '& > svg:last-of-type': {
      transform: 'rotate(180deg)',
    },
  },
});

export const SubmenuItem = styled(MenuItem)({
  padding: `${spacing[3]} ${spacing[6]}`,
});

export const MenuLink = styled(Link)({
  '&:hover': {
    textDecoration: 'none',
  },
});

export const ExternalMenuLink = styled('a')({
  '&:hover': {
    textDecoration: 'none',
  },
});

export const OptionsMenu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

export const LoaderWrapper = styled('div')({
  display: 'flex',
  padding: `${spacing[6]} 0`,
  justifyContent: 'center',
});
